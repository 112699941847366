import {
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthenticatedUser } from "@aveva/connect-web-core";
import { WindowRefService } from "../window-ref.service";
import { AppStreamFleetStack, AppStreamService, UserStatus } from "./appstream.service";
import { BehaviorSubject, merge, NEVER, Observable, Subject } from "rxjs";
import {
  catchError,
  concatMap,
  delay,
  filter,
  map,
  takeUntil,
  tap,
  repeat,
  switchMap,
  materialize,
  dematerialize,
} from "rxjs/operators";
import polling, { IOptions } from "rx-polling";
import { Subscription } from "rxjs/internal/Subscription";

export const POLL_INTERVAL_MS = new InjectionToken<number>("pollIntervalMs");
export const AUTOSTART_AFTER_SECONDS = new InjectionToken<number>("autoStartAfterSeconds");

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, OnDestroy {
  error: string;
  lastUserStatus: UserStatus;
  displayName: string;
  copied: boolean;
  appStreamFleetsStacks: AppStreamFleetStack[] = [];
  currentFolderPath: string[] = [];
  advancedMode = false;
  autoStartInProgress = false;
  autoStartFleetStackFound: boolean | null = null;

  UserStatus = UserStatus;
  validUserStatuses = Object.values(UserStatus);

  private pauser$ = new BehaviorSubject(false);
  private unsubscribe$: Subject<void> = new Subject<void>();
  private manualRefresh$: Subject<void> = new Subject<void>();
  private refreshFleetsStacks$: Subject<void> = new Subject<void>();
  private autoStart$: Subject<void> = new Subject<void>();
  private autoStartCancelled$: Subject<void> = new Subject<void>();
  private autoStartRestart$: Subject<void> = new Subject<void>();
  private refreshFleetsStacksSubscription: Subscription;
  private autoStartSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private appStreamService: AppStreamService,
    private windowRef: WindowRefService,
    private ref: ChangeDetectorRef,
    @Inject(POLL_INTERVAL_MS) private pollIntervalMs: number,
    @Inject(AUTOSTART_AFTER_SECONDS) private autoStartAfterSeconds: number
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data: { user: AuthenticatedUser; arrayOfPaths: string[] }) => {
      this.displayName = this.getDisplayName(data.user);
      this.currentFolderPath = data.arrayOfPaths;
    });

    this.route.queryParams.subscribe((params) => {
      this.advancedMode = Object.keys(params).includes("advanced");
    });

    this.refreshFleetsStacksSubscription = this.refreshFleetsStacks$
      .pipe(
        filter(() => this.lastUserStatus === UserStatus.Ready),
        switchMap(() => this.appStreamService.listAppStreamFleetsStacks()),
        tap((appStreamFleetsStacks: AppStreamFleetStack[]) => {
          this.appStreamFleetsStacks = appStreamFleetsStacks;
          this.autoStart$.next();
        }),
        catchError((error: Error, caught: Observable<any>) => { // eslint-disable-line @typescript-eslint/no-explicit-any
          this.error = error.message;
          return caught;
        })
      )
      .subscribe();

    this.autoStartSubscription = this.autoStart$
      .pipe(
        filter(() => !this.advancedMode),
        map(() =>
          // find first startable fleet with seats available, 3D has more prio than 2D
          [...this.appStreamFleetsStacks]
            .sort((x, y) => (x.instanceType || "").localeCompare(y.instanceType || ""))
            .find((x) => x.availableSeats > 0)
        ),
        tap((appStreamFleetStack) => (this.autoStartFleetStackFound = !!appStreamFleetStack)),
        filter((appStreamFleetStack) => !!appStreamFleetStack),
        tap(() => (this.autoStartInProgress = true)),
        delay(this.autoStartAfterSeconds * 1000),
        takeUntil(this.autoStartCancelled$),
        repeat({ delay: () => this.autoStartRestart$ })
      )
      .subscribe((appStreamFleetStack: AppStreamFleetStack) =>
        this.startAppStream(appStreamFleetStack.id)
      );

    const pollingSubject$ = this.pauser$.pipe(
      switchMap((paused) => {
        return paused ? NEVER : this.appStreamService.getUserStatus().pipe(materialize());
      }),
      dematerialize()
    );

    const pollingOptions: IOptions = {
      interval: this.pollIntervalMs,
      attempts: 1,
    };

    const manualRefreshSubject$ = this.manualRefresh$.pipe(
      concatMap(() => {
        return this.appStreamService.getUserStatus();
      })
    );

    merge(polling(pollingSubject$, pollingOptions) as Observable<UserStatus>, manualRefreshSubject$)
      .pipe(
        tap((userStatus: UserStatus) => {
          if (userStatus && userStatus !== UserStatus.Waiting) {
            this.pauser$.next(true);
          }
          this.lastUserStatus = userStatus;
        }),
        catchError((error: Error, caught: Observable<any>) => { // eslint-disable-line @typescript-eslint/no-explicit-any
          this.error = error.message;
          this.pauser$.next(true);
          return caught;
        }),
        takeUntil(this.unsubscribe$),
        filter((userStatus: UserStatus) => userStatus === UserStatus.Ready)
      )
      .subscribe(() => this.refreshFleetsStacks$.next());
  }

  ngOnDestroy() {
    if (this.refreshFleetsStacksSubscription) {
      this.refreshFleetsStacksSubscription.unsubscribe();
    }
    if (this.autoStartSubscription) {
      this.autoStartSubscription.unsubscribe();
    }
    this.autoStartCancelled$.next();
    this.autoStartCancelled$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.autoStart$.complete();
  }

  startSession() {
    this.autoStartFleetStackFound = null;
    this.appStreamFleetsStacks = undefined;
    this.refreshFleetsStacks$.next();
    this.autoStartRestart$.next();
  }

  startOver() {
    this.error = undefined;
    this.lastUserStatus = undefined;
    this.appStreamFleetsStacks = undefined;
    this.pauser$.next(false);
    this.autoStartFleetStackFound = null;
    this.autoStartInProgress = false;
    this.manualRefresh$.next();

    this.ref.detectChanges();
    this.ref.markForCheck();
  }

  cancelAutoStart() {
    this.autoStartInProgress = false;
    this.autoStartCancelled$.next();
  }

  startAppStream(appStreamFleetStackId: string) {
    this.appStreamService.startAppStream(appStreamFleetStackId).subscribe({
      next: (text: string) => {
        const document = this.windowRef.window.document;
        document.open();
        document.write(text); // eslint-disable-line
        document.close();
      },
      error: (error: Error) => {
        this.error = error.message;
      },
    });
  }

  getDisplayName(user: AuthenticatedUser): string {
    if (!user || !user.name) {
      return "";
    }

    if (typeof user.name !== "string") {
      return "";
    }

    let firstName = user.name.split(".")[0];
    if (!firstName) {
      return "";
    }

    if (firstName.includes("@")) {
      firstName = firstName.split("@")[0];
    }

    if (firstName.includes(".")) {
      firstName = firstName.split(".")[0].slice(0);
    }

    return firstName.charAt(0).toUpperCase() + firstName.slice(1);
  }
}
